.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*----- Font -----*/

/** Noto Sans(Google Fonts) */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

/** Montserrat(Google Fonts) */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --noto-sans: -apple-system, "Noto Sans KR", sans-serif;
}

html {
  font-family: var(--noto-sans);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: -0.5px;
}

/*----- Reset -----*/
@import-normalize;
/* bring in normalize.css styles */

* {
  box-sizing: border-box;
  word-break: keep-all;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  /* overflow-y: auto; */
  /* background-color: #181818; */
}

body {
  height: 100%;
  margin: 0;
}

ul,
ol,
dl,
li {
  padding: 0;
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

/*----- Common -----*/
.inner {
  display: flex;
  position: relative;
  height: 100%;
  letter-spacing: -0.6px;
}
h3 {
  line-height: 55px;
  font-size: 40px;
  color: #222222;
  letter-spacing: -1px;
  white-space: pre-line;
}
h3 span {
  display: block;
  margin-bottom: 20px;
  font-size: 26px;
  color: #ff2b53;
  letter-spacing: -0.6px;
}
.containerWrap {
  background-color: #ffffff;
}

/*----- Header -----*/
.headWrap {
  position: fixed;
  z-index: 900;
  width: 100%;
  height: 90px;
  background-color: transparent;
  top: 0;
}
.headWrap .inner {
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;
}
.headWrap .logo img {
  height: 30px;
  cursor: pointer;
}
.headWrap ul {
  display: flex;
}
.headWrap li {
  opacity: 0.8;
  font-size: 22px;
  color: #222222;
  cursor: pointer;
  transition: all 0.3s;
}
.headWrap li:hover {
  opacity: 1;
  font-weight: 500;
}
.headWrap li.active {
  opacity: 1;
  font-weight: 500;
}
.containerWrap {
  width: 100%;
  /* overflow-x: hidden; */
}

/*----- Footer -----*/
.footerWrap {
  padding: 50px 0;
  background-color: #181818;
  color: #ffffff;
}
.footerWrap .inner {
  justify-content: space-between;
}
.footerWrap .info ul {
  display: flex;
}
.footerWrap .btnWrap {
  font-size: 16px;
}
.footerWrap .btnWrap li {
  margin-right: 20px;
  cursor: pointer;
  letter-spacing: -0.3px;
}
.footerWrap .btnWrap li:first-child {
  opacity: 0.7;
  transition: all 0.3s;
}
.footerWrap .btnWrap li:first-child:hover {
  opacity: 1;
}
.footerWrap .info .text {
  margin: 30px 0;
  font-size: 14px;
  opacity: 0.5;
}
.footerWrap .info .text li {
  padding: 0 14px;
  line-height: 10px;
  border-right: 1px solid #ccc;
  letter-spacing: -0.3px;
}
.footerWrap .info .text li:first-child {
  padding-left: 0;
}
.footerWrap .info .text li:last-child {
  border-right: none;
}
.footerWrap .info .text li span {
  margin-left: 7px;
}
.footerWrap .info p {
  opacity: 0.5;
  letter-spacing: 0.6px;
}
.footerWrap .insta {
  width: 24px;
  height: 24px;
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.3s;
}
.footerWrap .insta:hover {
  opacity: 1;
}

.main {
  padding: 140px 0;
}
.main .info {
  margin-right: 220px;
}
.main .info ul {
  display: flex;
  margin-top: 50px;
}
.main .info li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 54px;
  margin-right: 16px;
  font-size: 16px;
  color: #ffffff;
  letter-spacing: 0;
  background-color: #181818;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
  font-family: "Montserrat", sans-serif;
}
.main .info li:hover {
  opacity: 0.8;
}
.main .info li img {
  margin-right: 8px;
}
.main .slide {
  position: relative;
}
.main .slide ul {
  display: flex;
  align-items: center;
  position: relative;
  height: 595px;
  padding-left: 20px;
}
.main .slide ul::after {
  display: block;
  top: 0;
  left: 0;
  width: 300px;
  height: 595px;
  position: absolute;
  background: url(assets/ImgMobile.png) center left no-repeat;
  clear: both;
  content: "";
}
.main .slide li {
  /* overflow: hidden; */
  margin-right: 50px;
  border-radius: 25px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 5px 14.25px 0.75px rgba(0, 0, 0, 0.1);
}
.main .slide li:not(:first-child) {
  margin-top: 60px;
}

.company .inner {
  padding: 100px 0;
}

.company h3 span {
  font-family: "Montserrat", sans-serif;
}
.company .info p {
  color: #555555;
  white-space: pre-line;
}
.company .atc01 .inner {
  padding-bottom: 225px;
  /* overflow: hidden; */
}
.company .atc01 .screen {
  position: absolute;
  top: 60px;
  right: 0;
}

.current-slide {
  transform: scale(1);
}

.mobile-slide:not(.current-slide),
.next-slide,
.prev-slide {
  transform: scale(0.8);
}

.slide:not(.current-slide, .next-slide, .prev-slide) {
  transform: scale(0.7);
}
