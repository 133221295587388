@keyframes gradient-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes gradient-2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.animation-1 {
  animation: gradient-1 5s ease infinite;
  animation-timing-function: linear;
}

.animation-2 {
  animation: gradient-2 5s ease infinite;
  animation-timing-function: linear;
}

.slick-list {
  height: 100%;
  display: flex !important;
  align-items: center !important;
}
